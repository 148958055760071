import React from "react"
import styled from "styled-components"

export const Header = styled.h1`
  text-align: center;
  margin: 5rem 2rem;
  color: ${props => props.theme.colors.primary};
  img {
    max-height: 150px;
  }
`
